/* eslint-disable complexity */
import React from "react";
import { OutlinedInput, Stack } from "@mui/material";
import { useUneeqAvatar } from "@elementx-ai/uneeq-sdk-react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";

import { TextInputChangeButton } from "./TextInputChangeButton";

import { MicButton } from "../button/MicButton";
import { getInputModeIsSpeech } from "../../store/app";
import { useThemeUtils } from "../../hooks/theme";
import { HomeButton } from "./HomeButton";

export const InteractionInput = () => {
  const { sendText, startRecording, stopRecording, avatarListening } =
    useUneeqAvatar();

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = (data: any) => {
    sendText(data?.message);
    reset();
  };

  const speechMode = useSelector(getInputModeIsSpeech);

  const { isMobile } = useThemeUtils();

  const onMouseDown = () => {
    startRecording();
  };

  const onMouseUp = () => {
    stopRecording();
  };

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
      mx={1}
      width={isMobile && !speechMode ? 1 : "auto"}
    >
      {!speechMode && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="message"
            control={control}
            render={({ field }) => (
              <OutlinedInput
                {...field}
                placeholder="Message"
                sx={{ width: isMobile ? 1 : 412, height: isMobile ? 36 : 68 }}
              />
            )}
          />
        </form>
      )}

      <HomeButton />

      <MicButton
        speechMode={speechMode}
        avatarListening={avatarListening}
        isMobile={isMobile}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      ></MicButton>

      {speechMode && <TextInputChangeButton />}
    </Stack>
  );
};
