import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import React from "react";
import { ControllerRenderProps, FieldValues, UseFormSetValue } from "react-hook-form";
import { FormField } from "../../util/schema";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";

export interface RenderMultiOptionProps {
  field: ControllerRenderProps<FieldValues, string>;
  data: FormField;
  key: any;
  setValue?: UseFormSetValue<FieldValues>;
}

export const RenderCheckbox = ({ field, data: { config, options, styles }, setValue }: RenderMultiOptionProps) => {
  const defaultConfig = useSelector(getConfig);

  const color = styles?.color ?? defaultConfig.defaultFormColor;

  return (
    <Stack direction={config?.direction}>
      {R.map(
        (option) => (
          <FormControlLabel
            control={
              <Checkbox
                {...field}
                checked={!!field.value?.includes(option)}
                onChange={() => setValue!(field.name, R.symmetricDifference(field.value ?? [], [option]))}
                sx={{
                  color,
                  "&.Mui-checked": {
                    color,
                  },
                }}
              />
            }
            label={<Typography sx={{ color }}>{option}</Typography>}
            key={option}
          />
        ),
        options!
      )}
    </Stack>
  );
};
