import React, { useEffect, useRef } from "react";
import {
  setUneeqApiUrl,
  UneeqSessionProvider,
} from "@elementx-ai/uneeq-sdk-react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";

import { LandingPage } from "./pages/landing/LandingPage";
import { theme } from "./theme";
import "./i18n";
import { LoadingPage } from "./pages/LoadingPage";
import { AvatarInteraction } from "./pages/AvatarInteraction";
import { fetchConfigValues, getAvatarOptions, getConfig } from "./store/config";
import { useSelector } from "react-redux";
import { getLanguage, getVoiceMode } from "./store/app";
import { useAppDispatch } from "./store/store";
import { mapResponseToConfig } from "./store/config/api";
import { AvatarUnavailable } from "./pages/AvatarUnavailable";

export const App = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const debug = searchParams.get("debug") === "true";
  const avatarRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  const config = useSelector(getConfig);

  const { avatarName } = config;

  if (avatarName) {
    document.title = avatarName;
  }

  const language = useSelector(getLanguage);
  let avatarOptions = useSelector(getAvatarOptions);
  const voiceMode = useSelector(getVoiceMode);
  avatarOptions.voiceInputMode = voiceMode;

  useEffect(() => {
    const setup = async () => {
      const { payload } = await dispatch(fetchConfigValues(language));
      if (!payload) {
        return;
      }

      const { url } = payload as ReturnType<typeof mapResponseToConfig>;
      dispatch(setUneeqApiUrl(url));
    };

    setup();
  }, [dispatch, language]);

  if (!config.tokenUrl) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme("dark")}>
      <CssBaseline />
      <Box height={1} width={1}>
        <UneeqSessionProvider
          avatarOptions={avatarOptions}
          tokenUrl={config.tokenUrl}
          landingPage={<LandingPage />}
          loadingPage={<LoadingPage />}
          avatarUnavailablePage={<AvatarUnavailable />}
          avatarRef={avatarRef}
          debug={debug}
        >
          <AvatarInteraction avatarRef={avatarRef} />
        </UneeqSessionProvider>
      </Box>
    </ThemeProvider>
  );
};
