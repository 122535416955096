import React from "react";
import { Stack, SxProps } from "@mui/material";
import { ClosedCaption, ClosedCaptionDisabled } from "@mui/icons-material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { useDispatch, useSelector } from "react-redux";

import { IconButtonWithLabel } from "./IconButtonWithLabel";

import { useThemeUtils } from "../../hooks/theme";
import { getShowCaptions, toggleShowCaptions } from "../../store/app";

export const iconSx: SxProps = {
  filter: "drop-shadow(0px 4px 4px rgb(0 0 0 / 0.25))",
};

export const ControlButtons = () => {
  const showClosedCaptions = useSelector(getShowCaptions);
  const { isMobile } = useThemeUtils();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const onCCPress = () => {
    dispatch(toggleShowCaptions());
  };

  return (
    <Stack direction={isMobile ? "column-reverse" : "row"} gap={isMobile ? 3 : 0}>
      <IconButtonWithLabel
        icon={
          showClosedCaptions ? <ClosedCaption sx={iconSx} /> : <ClosedCaptionDisabled sx={iconSx} />
        }
        label={t("captions")}
        onClick={onCCPress}
      />
    </Stack>
  );
};
