import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getClientConfig } from "./api";

export interface ConfigState {
  debug?: boolean;
  url?: string;
  conversationId?: string;
  tokenUrl?: string;
  talkJsUrl?: string;
  usePasscode?: boolean;
  recaptchaSiteKey?: string;
  playWelcome?: boolean;
  sendLocalVideo?: boolean;
  customData?: {};
  tapThreshold?: number;
  timeout?: number;
  timeoutWarning?: number;
  avatarAspect?: number;
  avatarPosition?: number;
  informationInTranscript?: boolean;
  showEscalationForm?: boolean;
  emptyTranscriptThreshold?: number;
  avatarName?: string;
  talkJSid?: string;
  videoPermissionsMP4?: string;
  videoPermissionsWebm?: string;
  videoPortraitIdleMP4?: string;
  videoPortraitIdleWebm?: string;
  videoLandingPageIdleMP4?: string;
  videoLandingPageIdleWebm?: string;
  imagePortraitIdle?: string;
  imageLandingPageIdle?: string;
  introVideo?: string;
  introVideoThumbnail?: string;
  loadingTips?: string[];
  mainMenuButtonText?: string;
  mainMenuButtonImage?: string;
  mainMenuButtonUtterance?: string;
  language?: string;
  locale?: string;
  googleAPIKey?: string;
  frontend_callback: string;
  defaultFormColor: string;
  landingPageSubtitle?: string;
  disclaimerOpen?: boolean;
  disclaimer1Title?: string;
  disclaimer1Link?: string;
  disclaimer2Title?: string;
  disclaimer2Link?: string;
  disclaimer3Title?: string;
  disclaimer3Link?: string;
  disclaimer4Title?: string;
  disclaimer4Link?: string;
  availableLanguages?: Record<string, string[]>;
}

const initialState: ConfigState = {
  debug: true,

  // Is a passcode needed to obtain a token? Show a passcode screen so the user can provide it.
  usePasscode: false,
  // Recaptcha - if used with passcode
  recaptchaSiteKey: process.env.REACT_APP_RECAPTCHA_SITE_KEY!,

  // welcome can be suppressed during dev with REACT_APP_NO_WELCOME=1
  playWelcome: !process.env.REACT_APP_NO_WELCOME,

  sendLocalVideo: false,
  customData: {},

  // When holding down spacebar, durations shorter that this will trigger
  // a message like "Keep holding spacebar while you talk"
  tapThreshold: 700, // ms

  // How long can the user be inactive before timeout
  timeout: 1800 * 1000, // ms
  // How close to the end of the timeout should we show the warning
  timeoutWarning: 180 * 1000, // ms
  // e.g. timeout=90sec, timeoutWarning=30sec - after 60 secs of inactivity warning will show, after 90 secs sessions ends

  // If the avatar is not centred within the video avatarPosition needs to be changed.
  // This will ensure correct positioning narrow (mobile) screens
  avatarAspect: 16 / 9, // video aspect ratio
  avatarPosition: 0.5, // Where is the avatar in the video frame (0.5 = center, 0.33 = 1/3 from the left)

  informationInTranscript: false,

  // show we show the contact form after Feedback?
  showEscalationForm: false,

  // How many empty transcripts before an error is shown
  emptyTranscriptThreshold: 3,

  // Default Client Values
  url: "",
  conversationId: "",
  tokenUrl: "",
  talkJsUrl: "",
  avatarName: "",
  talkJSid: "",
  videoPermissionsMP4: "",
  videoPermissionsWebm: "",
  videoPortraitIdleMP4: "",
  videoPortraitIdleWebm: "",
  videoLandingPageIdleMP4: "",
  videoLandingPageIdleWebm: "",
  imagePortraitIdle: "",
  imageLandingPageIdle: "",
  introVideo: "",
  introVideoThumbnail: "",
  loadingTips: [],
  googleAPIKey: "",
  mainMenuButtonText: "",
  mainMenuButtonImage: "",
  mainMenuButtonUtterance: "",
  language: "en",
  locale: "en-US",
  frontend_callback: "",
  defaultFormColor: "#00c8ff",
  disclaimerOpen: false,
  availableLanguages: {},
};

export const fetchConfigValues = createAsyncThunk(
  "config/fetchConfigValues",
  async (lang: string, thunkAPI) => {
    const response = await getClientConfig(lang);
    return response;
  }
);

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfigValues.fulfilled, (state, action) => {
      return { ...initialState, ...action.payload };
    });
  },
});
