import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Link,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { getConfig } from "../../store/config";
import { useSelector } from "react-redux";
import { useThemeUtils } from "../../hooks/theme";

export const Disclaimer = () => {
  const { t } = useTranslation();
  const { isMobile } = useThemeUtils()

  const {
    disclaimer1Title,
    disclaimer1Link,
    disclaimer2Link,
    disclaimer2Title,
    disclaimer3Link,
    disclaimer3Title,
    disclaimer4Link,
    disclaimer4Title,
    landingPageSubtitle,
    disclaimerOpen
  } = useSelector(getConfig);

  return (
    <Accordion defaultExpanded={isMobile ? false : disclaimerOpen}>
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ px: 0 }}>
        <Typography>{landingPageSubtitle}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0 }}>
        <Typography variant="caption">
          {t("landingPage.disclaimer")}{" "}
          <Link
            href={disclaimer1Link}
            target="_blank"
            rel="noreferrer noopener"
          >
            {disclaimer1Title}
          </Link>
          {disclaimer2Link && disclaimer2Title && (
            <>
              {`, `}
              <Link
                href={disclaimer2Link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {disclaimer2Title}
              </Link>
            </>
          )}
          {disclaimer3Link && disclaimer3Title && (
            <>
              {`, `}
              <Link
                href={disclaimer3Link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {disclaimer3Title}
              </Link>
            </>
          )}
          {disclaimer4Link && disclaimer4Title && (
            <>
              {`, ${t("and")} `}
              <Link
                href={disclaimer4Link}
                target="_blank"
                rel="noreferrer noopener"
              >
                {disclaimer4Title}
              </Link>
            </>
          )}

          {t("landingPage.privacy2")}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};
