import { PaletteMode } from "@mui/material";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

export const theme = (mode: PaletteMode = "dark") =>
  responsiveFontSizes(
    createTheme({
      palette: {
        primary: { main: "#FFFFFF" },
        secondary: { main: "#b5dafb" },
        background: { default: mode === "dark" ? "#2c2c2c" : "white" },
        mode,
      },
      typography: {
        h1: {
          fontSize: 80,
          fontWeight: "bold",
        },
        h2: {
          fontSize: 16,
        },
        h3: {
          fontSize: 40,
          fontWeight: 400,
        },
      },
      components: {
        MuiButton: {
          defaultProps: {
            variant: "contained",
            disableElevation: true,
          },
          styleOverrides: {
            root: ({ theme }) =>
              theme.unstable_sx({
                p: 1,
              }),
          },
        },
        MuiTypography: {
          defaultProps: {
            color: "text.primary",
          },
        },
        MuiSwitch: {
          defaultProps: {
            color: "warning",
          },
        },
        MuiAccordion: {
          styleOverrides: {
            root: {
              backgroundColor: "transparent",
              backgroundImage: "none",
              boxShadow: "none",
            },
          },
        },
        MuiLink: {
          defaultProps: {
            color: "text.primary",
          },
        },
        MuiInputBase: {
          styleOverrides: {},
        },
        MuiOutlinedInput: {
          styleOverrides: {
            input: {},
            root: {
              fontWeight: "bold",
              "& fieldset": {
                borderRadius: 40,
                overflow: "hidden",
              },
              backgroundColor: "white",
              borderRadius: 40,
              paddingLeft: 10,
              boxShadow: "0 2px 9px 0 rgba(0, 0, 0, 0.15)",
            },
          },
        },
      },
    })
  );
