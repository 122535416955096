import RebaBackground from "../assets/img/rebaBackground.jpg";
import TaylorBackground from "../assets/img/Taylor_Office.jpg";
import DefaultBackground from "../assets/img/receptionBackground.jpeg";
import XiBackground from "../assets/img/xiBackground.jpg";
import MayaAiBackground from "../assets/img/MayaAiBackground.jpg";
import LydiaBackground from "../assets/img/lydiaBackground.jpg";
import SophieBackground from "../assets/img/sophieBackground.jpg";

export const getBackground = (name: string) => {
  switch (name.toLowerCase()) {
    case "maya": {
      return `url(${MayaAiBackground})`;
    }
    case "maya ai": {
      return `url(${MayaAiBackground})`;
    }
    case "taylor": {
      return `url(${TaylorBackground})`;
    }
    case "reba": {
      return `url(${RebaBackground})`;
    }
    case "xiva": {
      return `url(${XiBackground})`;
    }
    case "lydia": {
      return `url(${LydiaBackground})`;
    }
    case "sophie": {
      return `url(${SophieBackground})`;
    }
    default: {
      return `url(${DefaultBackground})`;
    }
  }
};
