import React from "react";
import { Stack, Fade } from "@mui/material";

import { Hero, HeroProps } from "./Hero";

export const CoverDesktop = ({ onSubmit }: HeroProps) => (
  <Stack height="100%">
    <Fade in timeout={{ enter: 2000 }}>
      <Stack direction="row" alignItems="center" flexGrow={1}>
        <Hero onSubmit={onSubmit}></Hero>
      </Stack>
    </Fade>
  </Stack>
);
