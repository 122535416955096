import { setupLang } from "@elementx-ai/uneeq-sdk-react";

import en from "./lang/en.json";
import es from "./lang/es.json";

const i18n = setupLang({
  lng: "en",
  fallbackLng: ["en", "es"],
});

i18n.addResourceBundle("en", "translation", en);
i18n.addResourceBundle("es", "translation", es);

// eslint-disable-next-line import/no-default-export
export default i18n;
