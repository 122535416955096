import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MediaPermissionsErrorType, useUneeqMedia } from "@elementx-ai/uneeq-sdk-react";
import { isMacOs, isSafari } from "react-device-detect";

const getErrorMessage = (type?: MediaPermissionsErrorType) => {
  // https://github.com/gtechnologies/mic-check
  let title = "Failure to Enable Microphone";
  let message = "Something went wrong enabling your microphone.";

  if (isMacOs && type === MediaPermissionsErrorType.SystemPermissionDenied) {
    title = "System Permission Denied";
    message =
      "Your browser doesn't have System Preferences access to the microphone. Go to System Prefences -> Security and Privacy -> Microphone to give your browser access.";
  }

  if (type === MediaPermissionsErrorType.UserPermissionDenied) {
    title = "User Permission Denied";
    message = "It appears you have denied access to the microphone.";

    if (isSafari) {
      message +=
        " You will need to restart this experience and accept the permissions if you want to enable microphone.";
    } else {
      message +=
        " You will need to manually give permissions by clicking the microphone blocked icon in the address bar and restart this experience and accept the permissions if you want to enable microphone.";
    }
  }

  return { title, message };
};

export const PermissionError = () => {
  const { micPermissionError } = useUneeqMedia();

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  if (!micPermissionError) {
    return null;
  }

  const { title, message } = getErrorMessage(micPermissionError);

  return (
    <Dialog open={showErrorDialog}>
      <DialogTitle>
        <Typography variant="h5">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => window.location.reload()}>Restart</Button>
        <Button onClick={() => setShowErrorDialog(false)}>Continue Without Mic</Button>
      </DialogActions>
    </Dialog>
  );
};
