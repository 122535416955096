import { createStore } from "@elementx-ai/uneeq-sdk-react";
import { ThunkAction, Action } from "@reduxjs/toolkit";

import { appSlice, AppState } from "./app";
import { configSlice, ConfigState } from "./config";

import { useDispatch } from "react-redux";

export const reducer = {
  app: appSlice.reducer,
  config: configSlice.reducer,
};

export const store = createStore(reducer, [], {
  uneeq: {
    uneeqApiUrl: "https://api.us.uneeq.io",
  },
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = ReturnType<typeof store.getState> & {
  app: AppState;
  config: ConfigState;
};

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
