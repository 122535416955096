import React from "react";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CoverDesktop } from "./CoverDesktop";
import { CoverMobile } from "./CoverMobile";
import { HeroProps as HomeProps } from "./Hero";

import { theme } from "../../theme";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";
import { BackgroundVideo } from "./BackgroundVideo";

export const LandingPage = ({ onSubmit }: HomeProps) => {
  const darkTheme = theme("dark");
  const { imageLandingPageIdle, imagePortraitIdle } = useSelector(getConfig);

  const smallScreen = useMediaQuery(darkTheme.breakpoints.down("md"));
  const backgroundImage = `url(${smallScreen ? imagePortraitIdle : imageLandingPageIdle})`;

  return (
    <>
      <Box
        height={1}
        sx={{
          backgroundImage,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <BackgroundVideo />
        {smallScreen ? <CoverMobile /> : <CoverDesktop />}
      </Box>
    </>
  );
};
