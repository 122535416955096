import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

import { theme } from "../../theme";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";

export const BackgroundVideo = () => {
  const darkTheme = theme("dark");
  const {
    imageLandingPageIdle,
    imagePortraitIdle,
    videoPortraitIdleMP4,
    videoLandingPageIdleMP4,
    videoPortraitIdleWebm,
  } = useSelector(getConfig);

  const smallScreen = useMediaQuery(darkTheme.breakpoints.down("md"));

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={smallScreen ? imagePortraitIdle : imageLandingPageIdle}
      style={{
        objectFit: "cover",
        width: "100vw",
        height: "100vh",
        minHeight: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 0,
      }}
    >
      <source
        src={smallScreen ? videoPortraitIdleMP4 : videoLandingPageIdleMP4}
        type="video/mp4"
      />
      <source
        src={smallScreen ? videoPortraitIdleWebm : videoLandingPageIdleMP4}
        type="video/webm"
      />
    </video>
  );
};
