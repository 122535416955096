import React from "react";
import { useCaptions } from "@elementx-ai/uneeq-sdk-react";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import { getShowCaptions } from "../store/app";

export const Captions = () => {
  const caption = useCaptions(150);
  const showCaptions = useSelector(getShowCaptions) && caption;

  return (
    <Box
      sx={{
        backgroundColor: "rgba(29, 29, 29, 0.5)",
        p: 1,
        display: showCaptions ? "block" : "none",
      }}
    >
      <Typography color="white">{showCaptions && caption}</Typography>
    </Box>
  );
};
