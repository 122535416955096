import { MenuItem, TextField } from "@mui/material";
import React from "react";
import { RenderMultiOptionProps } from "./formCheckbox";
import * as R from "ramda";

export const RenderSelect = ({
  field,
  data: { defaultValue, options, placeholder },
  setValue,
}: RenderMultiOptionProps) => {
  return (
    <TextField
      {...field}
      id={field.name}
      select
      defaultValue={defaultValue ?? undefined}
      placeholder={placeholder ?? undefined}
      sx={{
        boxShadow: "none",
        borderRadius: 2,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: 2,
            height: 50,
          },
          "&.Mui-focused fieldset": {
            borderColor: "#00c8ff",
          },
          height: 50,
          pl: 1,
        },
      }}
      InputProps={{
        sx: {
          boxShadow: "none",
          fontWeight: 400,
        },
      }}
    >
      {R.map((option) => {
        return (
          <MenuItem key={option} value={option} onClick={() => setValue!(field.name, option)}>
            {option}
          </MenuItem>
        );
      }, options!)}
    </TextField>
  );
};
