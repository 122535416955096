import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { useSelector } from "react-redux";
import { getConfig } from "../store/config";

export const AvatarUnavailable = () => {
  const { t } = useTranslation();
  const { avatarName } = useSelector(getConfig);

  return (
    <Stack
      height={1}
      justifyContent="center"
      alignItems="center"
      gap={2}
      px={4}
      sx={{
        background: "black",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CircularProgress />
      <Typography>{t("avatarUnavailable.loadingMessage", { avatarName })}</Typography>
    </Stack>
  );
};
