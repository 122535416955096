import { SuggestedResponses } from "@elementx-ai/uneeq-sdk-react";
import { ExtIntDisplayHtml, PopupData } from "./schema";

/**
 * Extract the HTML from the old payload format where we had to put a stringified JSON in { display: { html } }
 *
 * @param displayHtml
 * @returns HTML string
 */
export const extractHtml = (displayHtml?: ExtIntDisplayHtml): PopupData => {
  if (!displayHtml?.html) {
    return { html: "" };
  }

  let data: PopupData = { html: "", autocomplete: false, showDefault: true };

  if (displayHtml?.src) {
    data.iFrameSrc = displayHtml.src;
  }

  if (displayHtml?.popopen) {
    data.showDefault = displayHtml.popopen.toLowerCase() === "n" ? false : true;
  }

  try {
    const payloadParse = JSON.parse(displayHtml?.html);
    if (payloadParse.information) {
      const info = payloadParse.information[0];
      if (!info.type) {
        // Assume that it is just html
        data.html = payloadParse.information;
        if (payloadParse.infoBackground) {
          data.background = payloadParse.infoBackground;
        }
      }
      const { type } = info;
      if (type === "autocomplete") {
        data.autocomplete = true;
      }
      if (type === "url") {
        data.iFrameSrc = info.source;
      }
      if (type === "form") {
        data.formData = info.form;
      }
    }
  } catch (error) {
    // If its not a valid JSON object, it's probably HTML
    data.html = displayHtml?.html;
  }

  return data;
};

/**
 * Extract the suggestedResponses from old format
 *
 * @param displayHtml
 * @returns HTML string
 */
export const extractSuggestedResponses = (displayHtml?: ExtIntDisplayHtml): SuggestedResponses | undefined => {
  const { html } = displayHtml ?? {};

  if (!html) {
    return;
  }

  try {
    const { mainTitle, suggestedResponses: options, multiselect } = JSON.parse(html);

    if (!options) {
      return;
    }

    return { mainTitle, options, multiselect };
  } catch {
    // If its not a valid JSON object, do nothing
    return;
  }
};
