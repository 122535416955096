import React, { useState } from "react";
import { useUneeqAvatar, useTranslation } from "@elementx-ai/uneeq-sdk-react";
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";
import { Stack, Typography, TextField, Button } from "@mui/material";

export const AutocompleteDropdown = () => {
  const { sendText } = useUneeqAvatar();
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(true);

  const config = useSelector(getConfig);
  const { googleAPIKey } = config;

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    useGoogle({
      apiKey: googleAPIKey || "AntiErrorKey",
      debounce: 100,
    });

  const chooseOption = (prediction: string) => {
    setValue(prediction);
    setShowDropdown(false);
  };

  const close = () => {};

  const submitValue = () => {
    sendText(value);
    close();
  };

  const submitCancel = () => {
    sendText("cancel");
    close();
  };

  const onInputChange:
    | React.ChangeEventHandler<HTMLInputElement>
    | undefined = (evt) => {
    setShowDropdown(true);
    getPlacePredictions({ input: evt.target.value });
    setValue(evt.target.value);
  };

  return (
    <Stack width={1}>
      <Stack alignItems="center">
        <Typography sx={{ color: "white", my: 2, fontSize: 20 }}>
          {t("Autocomplete.title")}
        </Typography>
      </Stack>
      <Stack direction="row">
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: 0,
                border: "solid white 1px",
              },
              "&:hover fieldset": {
                border: "solid white 1px",
              },
            },
            flexGrow: 2,
          }}
          value={value}
          onChange={onInputChange}
          placeholder={t("Autocomplete.placeholder")!}
          InputProps={{
            sx: {
              borderRadius: 0,
              boxShadow: "none",
            },
          }}
        />
        <Button
          variant="contained"
          sx={{
            color: "white",
            border: "solid white 1px",
            backgroundColor: "black",
            borderRadius: 0,
            px: 2,
          }}
          onClick={submitValue}
        >
          <Typography sx={{ color: "white", textTransform: "capitalize" }}>
            {t("Autocomplete.submit")}
          </Typography>
        </Button>
        <Button
          variant="contained"
          sx={{
            color: "white",
            border: "solid white 1px",
            backgroundColor: "lightgrey",
            borderRadius: 0,
            px: 2,
          }}
          onClick={submitCancel}
        >
          <Typography sx={{ color: "white", textTransform: "capitalize" }}>
            {t("Autocomplete.cancel")}
          </Typography>
        </Button>
      </Stack>
      <Stack alignContent="flex-start" gap={1} mt={1}>
        {showDropdown &&
          !isPlacePredictionsLoading &&
          placePredictions.map((prediction) => (
            <Stack
              key={prediction.description}
              onClick={() => chooseOption(prediction.description)}
              sx={{
                backgroundColor: "white",
                px: 2,
                py: 1,
                "&:hover": {
                  backgroundColor: "lightgrey",
                  border: "solid white 1px",
                },
              }}
              width={1}
            >
              {prediction.description}
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};
