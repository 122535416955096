import React from "react";
import { Box, Stack, Typography, Fade, Button, Switch, Alert } from "@mui/material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { isSafari } from "react-device-detect";

import { HeroProps } from "./Hero";

import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";
import { getVoiceMode, setVoiceMode } from "../../store/app";
import { useAppDispatch } from "../../store/store";
import { Disclaimer } from "./Disclaimer";

interface CoverMobileProps extends HeroProps {}

export const CoverMobile = ({ onSubmit }: CoverMobileProps) => {
  const { t } = useTranslation();
  const { avatarName } = useSelector(getConfig);
  const voiceMode = useSelector(getVoiceMode);
  const dispatch = useAppDispatch();

  const handleVoiceInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void = (_, checked) => {
    dispatch(setVoiceMode(checked ? "VOICE_ACTIVITY" : "PUSH_TO_TALK"));
  };

  const cookiesDisabled = !isSafari && !navigator.cookieEnabled;

  return (
    <Fade in timeout={{ enter: 2000 }}>
      <Box height={1} p={3}>
        <Stack height={1} justifyContent="space-between">
          <Box zIndex={1}>
            <Typography textAlign="center" variant="h1">
              {t("landingPage.title", { avatarName })}
            </Typography>
          </Box>

          <Stack>
            <Stack direction="row" zIndex={1} justifyContent="center" alignItems="center">
              {t("landingPage.voiceMode")}
              <Switch
                checked={voiceMode === "VOICE_ACTIVITY"}
                onChange={handleVoiceInputChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Stack>
            {cookiesDisabled && (
              <Alert severity="warning" sx={{ zIndex: 1 }}>
                {t("landingPage.cookiesDisabled")}
              </Alert>
            )}
            <Button
              sx={{ px: { md: 4 }, py: { xs: 1, md: 2 } }}
              type="submit"
              disabled={cookiesDisabled}
            >
              {t("landingPage.startSession")}
            </Button>
            <Box pt={2} px={2}>
              <Disclaimer />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Fade>
  );
};
