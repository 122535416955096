import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { useUneeqMedia } from "@elementx-ai/uneeq-sdk-react";

export const RequestingPermissions = () => {
  const { requestingMicPermissions } = useUneeqMedia();
  if (!requestingMicPermissions) {
    return null;
  }
  return (
    <Dialog open={requestingMicPermissions}>
      <DialogTitle>
        <Typography variant="h5">Requesting Permissions</Typography>
      </DialogTitle>
      <DialogContent>
        You should see a popup dialog on your screen. Please accept this dialog to chat with me over
        voice.
      </DialogContent>
    </Dialog>
  );
};
