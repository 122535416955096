import React from "react";
import { Stack, Box, Typography, Button, Switch, Alert } from "@mui/material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { isSafari } from "react-device-detect";

import { Disclaimer } from "./Disclaimer";

import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";
import { LangSelectButton } from "./LanguageSelectButton";
import { useAppDispatch } from "../../store/store";
import { getVoiceMode, setVoiceMode } from "../../store/app";

export interface HeroProps {
  onSubmit?: () => void;
}

export const Hero = ({ onSubmit }: HeroProps) => {
  const { t } = useTranslation();
  const { avatarName, availableLanguages } = useSelector(getConfig);
  const voiceMode = useSelector(getVoiceMode);
  const dispatch = useAppDispatch();

  const languageCount = Object.keys(availableLanguages!).length;

  const handleVoiceInputChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void = (_, checked) => {
    dispatch(setVoiceMode(checked ? "VOICE_ACTIVITY" : "PUSH_TO_TALK"));
  };

  const cookiesDisabled = !isSafari && !navigator.cookieEnabled;

  return (
    <Stack maxWidth={480} gap={2} ml={10} alignItems="flex-start">
      <Box zIndex={1}>
        <Typography variant="h1">
          {t("landingPage.title", { avatarName })}
        </Typography>
      </Box>
      <Box>
        <Disclaimer />
      </Box>
      <Box zIndex={1}>
        {t("landingPage.voiceMode")}
        <Switch
          checked={voiceMode === "VOICE_ACTIVITY"}
          onChange={handleVoiceInputChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>

      <Stack direction="row" gap={2}>
        <Button
          sx={{ px: { md: 4 }, py: { xs: 1, md: 2 } }}
          type="submit"
          disabled={cookiesDisabled}
        >
          {t("landingPage.startSession")}
        </Button>
        {languageCount > 1 && <LangSelectButton />}
      </Stack>

      {cookiesDisabled && (
        <Alert severity="warning" sx={{ zIndex: 1 }}>
          {t("landingPage.cookiesDisabled")}
        </Alert>
      )}
    </Stack>
  );
};
