import React from "react";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getInputModeIsSpeech, setInputMode } from "../../store/app";
import { styles } from "../styles";

export const TextInputChangeButton = () => {
  const speechMode = useSelector(getInputModeIsSpeech);
  const dispatch = useDispatch();

  const setToText = () => {
    // dispatch(setTextMode())

    // Temporary Toggle for dev purposes
    dispatch(setInputMode(speechMode ? "text" : "speech"));
  };

  return (
    <Stack
      onClick={setToText}
      onTouchEnd={setToText}
      sx={styles.outlinedButton}
    >
      Aa
    </Stack>
  );
};
