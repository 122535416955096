import { SxProps, Theme } from "@mui/material";

interface Styles {
  [x: string]: SxProps<Theme>;
}

export const styles: Styles = {
  outlinedButton: {
    height: 32,
    width: 32,
    border: "1px solid white",
    borderRadius: "50%",
    color: "black",
    backgroundColor: "white",
    fontSize: 12,
    cursor: "pointer",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: 3,
  },
};
