import React from "react";
import * as R from "ramda";
import { FormData, FormField } from "../../util/schema";
import { useForm, SubmitHandler, FieldValues, Controller, ControllerRenderProps } from "react-hook-form";
import axios from "axios";
import { Stack, Typography, Button } from "@mui/material";
import { FormInputs, InputTypes } from "../../types/formInputs";
import { useTranslation, selectSessionId } from "@elementx-ai/uneeq-sdk-react";
import { RenderCheckbox } from "./formCheckbox";
import { RenderRadio } from "./formRadio";
import { RenderSelect } from "./formSelect";
import { RenderInput } from "./formInput";
import { useSelector } from "react-redux";
import { getConfig } from "../../store/config";

interface FormRendererProps {
  form: FormData;
  closeForm: () => void;
}

const createTitle = (title: string, required?: boolean) => `${title}${required ? "*" : ""}`;

const textParser = (data: FormField) => {
  const { defaultValue, options, type } = data;
  if (!options) {
    return defaultValue;
  }

  if (type === InputTypes.Ordered) {
    return (
      <ol>
        {R.map(
          (option) => (
            <li>{option}</li>
          ),
          options
        )}
      </ol>
    );
  }

  return (
    <ul>
      {R.map(
        (option) => (
          <li>{option}</li>
        ),
        options
      )}
    </ul>
  );
};

const sendData = async (url: string, data: any, id: string, closeForm: () => void) => {
  await axios.post(url, {
    session_id: id,
    form_input: data,
  });

  return closeForm();
};

export const FormRenderer = ({ form, closeForm }: FormRendererProps) => {
  const { title, fields, buttonText, buttonStyles, styles } = form;
  const config = useSelector(getConfig);
  const id = useSelector(selectSessionId);
  const { handleSubmit, control, setValue } = useForm();
  const onSubmit: SubmitHandler<FieldValues> = (data) => sendData(config.frontend_callback, data, id!, closeForm);
  const { t } = useTranslation();

  const defaultButtonStyles = {
    color: "white",
    backgroundColor: config.defaultFormColor,
  };

  const switchInput = (field: ControllerRenderProps<FieldValues, string>, data: FormField) => {
    const { type } = data;
    switch (type) {
      case InputTypes.Checkbox:
        return <RenderCheckbox field={field} key={field.name} data={data} setValue={setValue} />;
      case InputTypes.Radio:
        return <RenderRadio field={field} key={field.name} data={data} />;
      default:
        return <RenderInput field={field} data={data} />;
    }
  };

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)} width={1} gap={1}>
      <Stack alignItems="center">
        <Typography sx={styles}>{title}</Typography>
      </Stack>
      <Stack gap={1}>
        {R.map((data) => {
          const { id, validate, element, styles, titleStyles, title: fieldTitle } = data;
          return (
            <>
              <Typography key={fieldTitle} sx={titleStyles}>
                {createTitle(fieldTitle ?? "", validate?.required)}
              </Typography>
              <Controller
                name={id}
                control={control}
                key={id}
                rules={{ required: validate?.required }}
                render={({ field }) => {
                  switch (element) {
                    case FormInputs.Input:
                      return switchInput(field, data);
                    case FormInputs.Select:
                      return <RenderSelect field={field} key={field.name} data={data} setValue={setValue} />;
                    case FormInputs.Text:
                      return <Typography sx={styles}>{textParser(data)}</Typography>;
                    default:
                      return <></>;
                  }
                }}
              />
            </>
          );
        }, fields)}
      </Stack>
      <Button type="submit" sx={buttonStyles ?? defaultButtonStyles}>
        {buttonText ?? t("submit")}
      </Button>
    </Stack>
  );
};
