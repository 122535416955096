import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import React from "react";
import { RenderMultiOptionProps } from "./formCheckbox";
import * as R from "ramda";
import { getConfig } from "../../store/config";
import { useSelector } from "react-redux";

export const RenderRadio = ({ field, data: { defaultValue, options, styles } }: RenderMultiOptionProps) => {
  const config = useSelector(getConfig);

  const color = styles?.color ?? config.defaultFormColor;
  return (
    <Stack>
      <RadioGroup {...field} row defaultValue={defaultValue}>
        {R.map(
          (option) => (
            <FormControlLabel
              value={option}
              control={
                <Radio
                  sx={{
                    color,
                    "&.Mui-checked": {
                      color,
                    },
                  }}
                />
              }
              label={<Typography sx={{ color }}>{option}</Typography>}
              key={option}
            />
          ),
          options!
        )}
      </RadioGroup>
    </Stack>
  );
};
