import React from "react";
import { Box, IconButton, Stack, Typography } from "@mui/material";

interface IconButtonWithLabelProps {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
}

export const IconButtonWithLabel = (props: IconButtonWithLabelProps) => {
  const { icon, label, onClick } = props;

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      sx={{ cursor: "pointer", maxHeight: 64, textShadow: "0px 4px 4px #00000040" }}
      p={1}
    >
      <Box> &nbsp;</Box>
      <IconButton color="primary">{icon}</IconButton>
      <Typography variant="caption" color="white" flex={1}>
        {label}
      </Typography>
    </Stack>
  );
};
