/* eslint-disable complexity */
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { Mic } from "@mui/icons-material";
import { Box, Button, Tooltip } from "@mui/material";

import "./ripple.scss";

interface MicButtonProps {
  speechMode: boolean;
  avatarListening?: boolean;
  isMobile?: boolean;
  onClick?: () => void;
  onMouseDown?: () => void;
  onMouseUp?: () => void;
  tooltipOpen?: boolean;
}

export const MicButton = ({
  speechMode,
  avatarListening,
  isMobile,
  onClick,
  onMouseDown,
  onMouseUp,
  tooltipOpen,
}: MicButtonProps) => {
  const { t } = useTranslation();

  const background = avatarListening
    ? "linear-gradient(155.21deg, #03DAC5 10.7%, #07b5a4 90.03%)"
    : "#FF6B59";

  const speechSize = speechMode ? (isMobile ? 57 : 64) : isMobile ? 28 : 48;
  const speechStyles = {
    width: `${2 * speechSize}px`,
    height: `${2 * speechSize}px`,
    left: `${-0.5 * speechSize}px`,
    top: `${-0.5 * speechSize}px`,
    borderRadius: `${2 * speechSize}px`,
  };

  return (
    <Tooltip
      placement="top"
      open={tooltipOpen}
      title={isMobile ? t("mobileTooltip") : t("desktopTooltip")}
      arrow
    >
      <Button
        size={speechMode ? "small" : "large"}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchEnd={onMouseUp}
        centerRipple
        sx={{
          borderRadius: "50%",
          ":hover": { background },
          background,
          width: speechSize,
          height: speechSize,
          minWidth: speechSize,
          zIndex: 2,
          boxShadow: 3,
        }}
      >
        {avatarListening && (
          <Box className="ripple-animation">
            <Box className="ripple ripple-background" sx={speechStyles}></Box>
            <Box className="ripple ripple-background" sx={speechStyles}></Box>
            <Box className="ripple ripple-background" sx={speechStyles}></Box>
          </Box>
        )}
        <Mic
          sx={{
            width: speechMode ? (isMobile ? 24 : 30) : isMobile ? 16 : 24,
            height: speechMode ? 30 : 24,
            color: avatarListening ? "white" : "black",
            zIndex: 2,
          }}
        />
      </Button>
    </Tooltip>
  );
};
