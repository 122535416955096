import { createSlice } from "@reduxjs/toolkit";

export type InputMode = "speech" | "text";
export type VoiceMode = "PUSH_TO_TALK" | "VOICE_ACTIVITY";
export interface AppState {
  inputMode: InputMode;
  showCaptions: boolean;
  language: string;
  voiceMode: VoiceMode;
}

const initialState: AppState = {
  inputMode: "speech",
  showCaptions: false,
  language: "en",
  voiceMode: "PUSH_TO_TALK",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setInputMode: (state, action) => {
      state.inputMode = action.payload;
    },
    setSpeechMode: (state) => {
      state.inputMode = "speech";
    },
    setTextMode: (state) => {
      state.inputMode = "text";
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setVoiceMode: (state, action) => {
      state.voiceMode = action.payload;
    },
    toggleShowCaptions: (state) => {
      state.showCaptions = !state.showCaptions;
    },
  },
});
