import React from "react";
import { Button, Box } from "@mui/material";
import { ExitToApp } from "@mui/icons-material";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";

import { IconButtonWithLabel } from "./IconButtonWithLabel";

import { useThemeUtils } from "../../hooks/theme";
import { iconSx } from "./ControlButtons";

export const LeaveButton = () => {
  const { isMobile } = useThemeUtils();
  const { t } = useTranslation();

  const onClick = () => {
    window.location.reload();
  };

  return (
    <Box>
      {isMobile ? (
        <IconButtonWithLabel icon={<ExitToApp sx={iconSx} />} label={t("leaveChat")} onClick={onClick} />
      ) : (
        <Button onClick={onClick} variant="outlined" startIcon={<ExitToApp />} sx={{ m: 2 }}>
          {t("leaveChat")}
        </Button>
      )}
    </Box>
  );
};
