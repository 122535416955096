import { useUneeqAvatar } from "@elementx-ai/uneeq-sdk-react";
import { Close, Info } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Stack, SxProps } from "@mui/material";
import * as DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import { AutocompleteDropdown } from "./autocomplete/AutocompleteDropdown";

import { useThemeUtils } from "../hooks/theme";
import { FormData } from "../util/schema";
import { extractHtml } from "../util/uneeq";
import { iconSx } from "./button/ControlButtons";
import { IconButtonWithLabel } from "./button/IconButtonWithLabel";
import { FormRenderer } from "./forms/formParser";

const mobilePaperSx: SxProps = {
  pointerEvents: "auto",
  minWidth: 200,
  md: 18,
};

const desktopPaperSx: SxProps = {
  pointerEvents: "auto",
  minWidth: 0.4,
  minHeight: 0.8,
  m: 8,
};

const autoCompleteDesktop: SxProps = {
  ...desktopPaperSx,
  background: "none",
  boxShadow: "none",
};

const autoCompleteMobile: SxProps = {
  ...mobilePaperSx,
  background: "none",
  boxShadow: "none",
};

const offersPopupDesktopSx = (background: string): SxProps => {
  return {
    pointerEvents: "auto",
    m: 8,
    backgroundImage: background,
  };
};

export const InformationDialog = ({ showDialog, setShowDialog }: { showDialog: boolean; setShowDialog: Function }) => {
  const [dismissed, setDismissed] = useState(false);
  const [cleanHtml, setCleanHtml] = useState("");
  const [autocomplete, setAutocomplete] = useState(false);
  const [offersPopup, setOffersPopup] = useState<string>();
  const [src, setSrc] = useState<string>();
  const [form, setForm] = useState<FormData>();

  const { displayHtml } = useUneeqAvatar();
  const { isMobile } = useThemeUtils();

  useEffect(() => {
    const { autocomplete, background, html, iFrameSrc, formData, showDefault } = extractHtml(displayHtml);
    setAutocomplete(autocomplete || false);
    setOffersPopup(background);
    setCleanHtml(DOMPurify.sanitize(html));
    setSrc(iFrameSrc);
    setForm(formData);
    setDismissed(false);
    setShowDialog(showDefault);
  }, [displayHtml, setShowDialog]);

  const backgroundSelector = () => {
    if (form?.styles?.backgroundColor) {
      return {
        ...desktopPaperSx,
        backgroundColor: form.styles.backgroundColor,
      };
    }
    if (isMobile) {
      return autocomplete ? autoCompleteMobile : mobilePaperSx;
    }
    if (autocomplete) {
      return autoCompleteDesktop;
    }
    if (offersPopup) {
      return offersPopupDesktopSx(offersPopup);
    }

    return desktopPaperSx;
  };

  const setInfoDataPayload = () => {
    setForm(undefined);
  };

  const background = backgroundSelector();

  const containsInfoPopup = !!src || cleanHtml !== "" || autocomplete || form;
  const open = showDialog && !dismissed && (!!cleanHtml || !!src || autocomplete || !!form);

  let contentPadding = 0;

  if (autocomplete || offersPopup) {
    contentPadding = 2.5;
  }

  if (background) {
    contentPadding = 5;
  }

  return (
    <Stack
      sx={{
        height: 1,
        width: 1,
        padding: 5,
        flexDirection: "column-reverse",
      }}
    >
      {containsInfoPopup && (
        <Stack
          sx={{
            flexDirection: "row",
            ...(isMobile ? { paddingBottom: 1 } : { paddingLeft: 3 }),
          }}
        >
          <IconButtonWithLabel
            icon={
              showDialog ? (
                <Close sx={{ ...iconSx, fontSize: 32 }} />
              ) : (
                <Info sx={{ ...iconSx, fontSize: isMobile ? 38 : 32 }} />
              )
            }
            label=""
            onClick={() => setShowDialog(!showDialog)}
          />
        </Stack>
      )}
      <Dialog
        open={open}
        hideBackdrop
        disableEnforceFocus
        disableAutoFocus
        disablePortal
        sx={{
          pointerEvents: "none",
          ...(isMobile ? {} : { maxHeight: "90%" }),
          "& .MuiDialog-container": {
            justifyContent: isMobile ? "center" : "flex-start",
            alignItems: isMobile ? "flex-start" : "center",
          },
        }}
        PaperProps={{
          sx: background,
        }}
        fullWidth={isMobile}
      >
        <IconButton onClick={() => setDismissed(false)} sx={{ position: "absolute", right: 4, top: 4 }}>
          <Close sx={{ ...iconSx, ...(isMobile ? { fontSize: 36 } : {}) }} />
        </IconButton>
        <DialogContent
          sx={{
            display: "flex",
            p: contentPadding,
          }}
        >
          {src ? (
            <iframe title="information-dialog" src={src} style={{ flexGrow: 1, border: "none" }}></iframe>
          ) : autocomplete ? (
            <AutocompleteDropdown />
          ) : form ? (
            <FormRenderer form={form} closeForm={setInfoDataPayload} />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: cleanHtml }}></div>
          )}
        </DialogContent>
      </Dialog>
    </Stack>
  );
};
