import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getConfig } from "../store/config";

export const LoadingPage = () => {
  const { loadingTips } = useSelector(getConfig);

  const [chosenTipIndex, setChosenTipIndex] = useState(0);

  useEffect(() => {
    if (loadingTips)
      setChosenTipIndex(Math.floor(Math.random() * loadingTips.length));
  }, [loadingTips, setChosenTipIndex]);

  return (
    <Stack
      height={1}
      justifyContent="center"
      alignItems="center"
      gap={2}
      px={4}
      sx={{
        background: "black",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <CircularProgress />
      <Typography>{loadingTips && loadingTips[chosenTipIndex]}</Typography>
    </Stack>
  );
};
