export enum FormInputs {
  Input = "input",
  Select = "select",
  Text = "text"
}

export enum InputTypes {
  Text = "text",
  Email = "email",
  Number = "number",
  Password = "password",
  Submit = "submit",
  File = "file",
  Checkbox = "checkbox",
  Radio = "radio",
  Reset = "reset",
  Ordered = "ordered",
  Unordered = "unordered",
}

export type StackDirection = "row" | "row-reverse" | "column" | "column-reverse"
