import React from "react";
import { Stack } from "@mui/material";

import { ControlButtons } from "./button/ControlButtons";

import { useThemeUtils } from "../hooks/theme";
import { LeaveButton } from "./button/LeaveButton";

export const SideBar = () => {
  const { isMobile } = useThemeUtils();

  return (
    <Stack position="absolute" top={0} right={0} p={1}>
      <LeaveButton />
      {isMobile && <ControlButtons />}
    </Stack>
  );
};
