import React, { useCallback, useEffect, useState } from "react";
import { Stack, ThemeProvider } from "@mui/material";
import { Avatar, useUneeqAvatar } from "@elementx-ai/uneeq-sdk-react";
import { useKeyPressEvent } from "react-use";

import avatarImg from "../assets/img/avatar.png";
import { theme } from "../theme";
import { BottomBar } from "../components/bottomBar/BottomBar";
import { SideBar } from "../components/SideBar";
import { PermissionError } from "../components/permissions/PermissionError";
import { RequestingPermissions } from "../components/permissions/RequestingPermissions";
import { Captions } from "../components/Captions";
import { InformationDialog } from "../components/InformationDialog";
import { SuggestedResponses } from "../components/SuggestedResponses";
import { useThemeUtils } from "../hooks/theme";
import { extractHtml } from "../util/uneeq";
import useTalkJs from "../hooks/useTalkJs";
import { useSelector } from "react-redux";
import { getLanguage } from "../store/app";
import { getConfig } from "../store/config";
import { getBackground } from "../util/background";
import { PopupData } from "../util/schema";

interface AvatarInteractionProps {
  avatarRef: React.Ref<HTMLDivElement>;
}

export const AvatarInteraction = ({ avatarRef }: AvatarInteractionProps) => {
  const [splitScreenCommand, setSplitScreenCommand] = useState(false);
  const [rememberedTalkJSOpen, setRememberedTalkJSOpen] = useState(false);
  const [GPTLoadingResponse, setGPTLoadingResponse] = useState<string | undefined>(undefined);
  const [showDialog, setShowDialog] = useState(false);

  const { avatarName, talkJsUrl } = useSelector(getConfig);
  const [isHalfScreen, setHalfScreen] = useState(false);
  const [previousHalfScreen, setPreviousHalfScreen] = useState(isHalfScreen);
  const language = useSelector(getLanguage);

  const { startRecording, stopRecording, displayHtml, customData } = useUneeqAvatar();
  const { isMobile } = useThemeUtils();

  const background = getBackground(avatarName ?? "");

  useTalkJs(avatarName!, talkJsUrl!, language);

  const onKeyPress = (keyDown: boolean) => (event?: KeyboardEvent) => {
    if (
      event?.target instanceof Element &&
      event?.target.nodeName.toLowerCase() === "input"
    ) {
      return;
    }

    if (keyDown) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  // Split screen behaviour
  const { splitScreen } = customData ?? {};

  const canHalfScreen = useCallback(
    (htmlData: PopupData) => {
      const { iFrameSrc, html, autocomplete, formData } = htmlData;
      const containsInfoPopup = !!iFrameSrc || html !== "" || autocomplete || formData;

      return !isMobile && (containsInfoPopup || splitScreenCommand);
    },
    [isMobile, splitScreenCommand]
  );

  useEffect(() => {
    // For when they send a payload to keep it split screened
    if (splitScreen === undefined) {
      return;
    }

    setSplitScreenCommand(splitScreen);
  }, [splitScreen]);

  useEffect(() => {
    const { showDefault, ...htmlData } = extractHtml(displayHtml);
    const { aiLoading } = customData || {};

    setGPTLoadingResponse(aiLoading);

    const halfScreen = canHalfScreen(htmlData) && showDefault !== false;

    setHalfScreen(halfScreen);

    // Only continue if the screen is actually changing
    if (halfScreen === previousHalfScreen) return;
    setPreviousHalfScreen(halfScreen);

    // Select element of talkjs button if it is OPEN
    const openTalkJSButton: HTMLElement | null = document.querySelector(".__talkjs_launcher.open");
    const closedTalkJSButton: HTMLElement | null = document.querySelector(".__talkjs_launcher.closed");

    // Minimize TalkJS if going to half screen and is open
    if (halfScreen && openTalkJSButton) {
      openTalkJSButton.click();
      setRememberedTalkJSOpen(true);
      return;
    }

    // If not half screen, return the talkjs back to previous state
    if (!halfScreen && closedTalkJSButton && rememberedTalkJSOpen) {
      closedTalkJSButton.click();
      setRememberedTalkJSOpen(false);
    }
  }, [displayHtml, rememberedTalkJSOpen, previousHalfScreen, customData, canHalfScreen]);

  useEffect(() => {
    if (canHalfScreen(extractHtml(displayHtml))) {
      setHalfScreen(showDialog);
    }
  }, [displayHtml, showDialog, canHalfScreen]);

  useKeyPressEvent(" ", onKeyPress(true), onKeyPress(false));

  return (
    <ThemeProvider theme={theme("light")}>
      <Stack
        position="absolute"
        height={1}
        width={1}
        justifyContent="flex-end"
        alignItems="center"
        sx={{
          background: background,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Avatar
          ref={avatarRef}
          avatarImage={avatarImg}
          style={{
            height: "90%",
            maxWidth: isHalfScreen ? "50%" : "100%",
            transform: isHalfScreen ? "translateX(50%)" : "translateX(0)",
            transition: "all 500ms",
          }}
        ></Avatar>
      </Stack>

      <SideBar />
      <PermissionError />
      <RequestingPermissions />
      <InformationDialog showDialog={showDialog} setShowDialog={setShowDialog} />

      <Stack
        position="absolute"
        right={0}
        height={1}
        bottom={0}
        width={isHalfScreen ? 0.5 : 1}
        gap={2}
        justifyContent="flex-end"
        alignItems="center"
        sx={{ pointerEvents: "none", transition: "all 250ms" }}
      >
        <Captions />
        <SuggestedResponses />
        <BottomBar GPTLoadingResponse={GPTLoadingResponse} />
      </Stack>
    </ThemeProvider>
  );
};
