import React from "react";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

import { getConfig } from "../../store/config";
import { useUneeqAvatar } from "@elementx-ai/uneeq-sdk-react";
import { styles } from "../styles";

export const HomeButton = () => {
  const { mainMenuButtonUtterance, mainMenuButtonImage } =
    useSelector(getConfig);
  const { sendText } = useUneeqAvatar();

  return (
    <>
      {mainMenuButtonImage && (
        <Stack
          sx={styles.outlinedButton}
          onClick={() => sendText(mainMenuButtonUtterance)}
        >
          {
            <img
              style={{ width: "16px", height: "16px" }}
              alt="mainMenu"
              src={mainMenuButtonImage}
            />
          }
        </Stack>
      )}
    </>
  );
};
