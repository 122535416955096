import React from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "@elementx-ai/uneeq-sdk-react";
import { getLanguage, setLanguage } from "../../store/app";
import { useAppDispatch } from "../../store/store";
import { getConfig } from "../../store/config";

export const LangSelectButton = ({ sx }: any) => {
  const { t, i18n } = useTranslation();

  const lang = useSelector(getLanguage);
  const config = useSelector(getConfig);

  const languages = Object.keys(config.availableLanguages!);

  const dispatch = useAppDispatch();

  const changeLanguage = (e: any) => {
    const { value } = e.target;
    // i18n will automatically use fallback (en) if the language is not available
    i18n.changeLanguage(value);
    dispatch(setLanguage(value));
  };

  return (
    <Select
      id="language-select"
      name="language-select"
      defaultValue={lang}
      onChange={changeLanguage}
      sx={{
        background: "transparent",
        "& fieldset": {
          borderRadius: 1,
        },
        borderRadius: 1,
        pl: 0,

        lineHeight: "24px",
        fontWeight: "600",
        cursor: "pointer",
        zIndex: 4,
        "&:focus": {
          outline: "none",
        },
      }}
    >
      {languages.map((language, index) => {
        return (
          <MenuItem value={language} key={language}>
            {t(`landingPage.${language}Select`, language)}
          </MenuItem>
        );
      })}
    </Select>
  );
};
