import { TextField } from "@mui/material";
import React from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import { FormField } from "../../util/schema";

export interface RenderInputProps {
  field: ControllerRenderProps<FieldValues, string>;
  data: FormField;
}

export const RenderInput = ({
  field,
  data: { type, validate, defaultValue, placeholder, config, styles },
}: RenderInputProps) => {
  const { required, min, max } = validate || {};
  const color = styles?.color || "black";
  const backgroundColor = styles?.backgroundColor || "white";

  const baseStyles = {
    boxShadow: "none",
    borderRadius: 2,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: color,
      },
    },
    "& .MuiInputBase-input": {
      py: 1,
      px: 0.5,
    },
    "& .MuiInputBase-multiline": {
      py: 0,
      px: 1,
    },
    color,
    backgroundColor,
  };

  return (
    <TextField
      {...field}
      id={field.name}
      type={type ? type : "text"}
      variant="outlined"
      defaultValue={defaultValue}
      placeholder={placeholder}
      required={!!required}
      multiline={config?.multiline}
      minRows={config?.minRows}
      maxRows={config?.maxRows}
      rows={config?.rows}
      key={field.name}
      fullWidth
      sx={{ ...baseStyles, ...styles }}
      InputProps={{
        sx: {
          boxShadow: "none",
          fontWeight: 400,
          color,
          backgroundColor,
        },
      }}
      inputProps={{
        min,
        max,
      }}
    />
  );
};
