/**
 * In this component pointerEvents are disabled. In order to enable click events you need to set pointerEvents to 'auto'
 */
import {
  SuggestedResponses as SuggestedResponsesType,
  useUneeqAvatar,
} from "@elementx-ai/uneeq-sdk-react";
import { TransitionGroup } from "react-transition-group";
import { Stack } from "@mui/system";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  Button,
  Fade,
  Slide,
  StackProps,
  styled,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import { extractSuggestedResponses } from "../util/uneeq";
import { useThemeUtils } from "../hooks/theme";

const ButtonStack = styled(Stack)<StackProps>({
  pointerEvents: "auto",
});

ButtonStack.defaultProps = {
  direction: "row",
  gap: 3,
  flexWrap: "wrap",
  justifyContent: "center",
};

export const SuggestedResponses = () => {
  const [sentUtterance, setSentUtterance] = useState(false);
  const [suggestedResponses, setSuggestedResponses] =
    useState<SuggestedResponsesType>();
  const [selectedResponses, setSelectedResponses] = useState<number[]>([]);

  const { isMobile } = useThemeUtils();
  const { sendText, displayHtml } = useUneeqAvatar();

  useEffect(() => {
    const suggestedResponses = extractSuggestedResponses(displayHtml);
    setSuggestedResponses(suggestedResponses);
  }, [displayHtml]);

  useEffect(() => {
    // Reset the state when it changes
    setSentUtterance(false);
  }, [suggestedResponses]);

  const toggleSelectedResponse = (index: number) => {
    const responseIndex = selectedResponses.indexOf(index);
    responseIndex === -1
      ? setSelectedResponses([...selectedResponses, index])
      : setSelectedResponses([
          ...selectedResponses.slice(0, responseIndex),
          ...selectedResponses.slice(responseIndex + 1),
        ]);
  };

  const options = sentUtterance ? undefined : suggestedResponses?.options;
  const showTitle = !!options && !!suggestedResponses?.mainTitle;

  return (
    <Stack alignItems="center" gap={2}>
      <Fade in={showTitle} unmountOnExit mountOnEnter>
        <Typography
          color="white"
          px={{ xs: 4, md: 12 }}
          sx={{ textShadow: "1px 2px 2px #222222" }}
        >
          {suggestedResponses?.mainTitle}
        </Typography>
      </Fade>
      <TransitionGroup component={ButtonStack}>
        {options?.map((option, idx) => (
          <Slide
            key={`${idx}-${option.label}`}
            direction="left"
            style={{ transitionDelay: `${idx * 100}ms` }}
          >
            <Button
              color={
                selectedResponses.indexOf(idx) === -1 ? "primary" : "secondary"
              }
              onClick={() => {
                if (suggestedResponses?.multiselect)
                  return toggleSelectedResponse(idx);

                setSentUtterance(true);
                sendText(option.utterance);
              }}
            >
              <Typography variant={isMobile ? "caption" : "body1"}>
                {option.label}
              </Typography>
            </Button>
          </Slide>
        ))}
      </TransitionGroup>
      {options && suggestedResponses?.multiselect && (
        <TransitionGroup component={ButtonStack}>
          <Fade in={showTitle} unmountOnExit mountOnEnter>
            <Button
              sx={{ ":hover": { cursor: "pointer" }, backgroundColor: "black" }}
              onClick={() => {
                setSentUtterance(true);
                setSelectedResponses([]);
                sendText(
                  options
                    .filter(
                      (_, index) => selectedResponses.indexOf(index) !== -1
                    )
                    .map(({ utterance }) => utterance)
                    .join(", ")
                );
              }}
            >
              <Typography
                variant={isMobile ? "caption" : "body1"}
                color="white"
              >
                {suggestedResponses.multiselect}
              </Typography>
              <ChevronRightIcon sx={{ color: "white" }} />
            </Button>
          </Fade>
        </TransitionGroup>
      )}
    </Stack>
  );
};
