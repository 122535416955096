import { RootState } from "../store";

export const getConfig = (state: RootState) => state.config;

export const getAvatarOptions = (state: RootState) => {
  // Default locale is whatever is provided for en, or en-US
  const defaultLocales = state.config.availableLanguages!["en"] ?? ["en-US"];

  return {
    enableTransparentBackground: true,
    playWelcome: true,
    sendLocalAudio: true,
    sendLocalVideo: false,
    url: state.config.url!,
    conversationId: state.config.conversationId!,
    voiceInputMode: "VOICE_ACTIVITY",
    speechToTextLocales: (
      state.config.availableLanguages![state.config.language!] ?? defaultLocales
    ).join(":"),
  };
};
