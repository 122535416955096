import { Box, Stack, Typography, LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";

import { InteractionInput } from "./InteractionInput";

import { ControlButtons } from "../button/ControlButtons";
import { getInputModeIsSpeech } from "../../store/app";
import { useThemeUtils } from "../../hooks/theme";

export const BottomBar = ({ GPTLoadingResponse }: { GPTLoadingResponse: string | undefined }) => {
  const { isMobile } = useThemeUtils();
  const speechMode = useSelector(getInputModeIsSpeech);

  return (
    <Stack
      mb={isMobile ? (speechMode ? 5 : 0) : 3.75}
      zIndex={1}
      width={1}
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        ...(isMobile &&
          !speechMode && {
            height: 48,
            width: "100%",
            background: "rgba(255, 255, 255, 0.5)",
          }),
        pointerEvents: "none",
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        flexGrow={1}
        flexBasis={0}
      >
        <Typography
          visibility={GPTLoadingResponse ? "visible" : "hidden"}
          color="green"
          paddingRight={2}
        >
          {`${GPTLoadingResponse}`}
          <LinearProgress color="success" />
        </Typography>
      </Stack>

      <Stack sx={{pointerEvents: "auto"}}>
        <InteractionInput />
      </Stack>

      <Stack
        direction="row"
        justifyContent="flex-start"
        flexGrow={1}
        flexBasis={0}
        sx={{pointerEvents: "auto"}}
      >
        {!isMobile ? <ControlButtons /> : <Box />}
      </Stack>
    </Stack>
  );
};
